<template>
    <div>
        <form id="product-form" @submit.prevent="submitForm">
            <div class="mb-2">
                <div class="image-container" @click="resetImage" style="cursor: pointer;">
                    <img :class="{ opacity: loadingImage}" :src="product.image_url" alt="Product Image">
                    <Spinner class="image-spinner" v-if="loadingImage" size="60" line-fg-color="gray" />
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="form-group col">
                    <label class="col-form-label" for="name">Name</label>
                    <input
                        class="form-control form-control-sm"
                        type="text"
                        maxlength="50"
                        required
                        v-model="product.name"
                    />
                </div>
                <div class="form-group col">
                    <label class="col-form-label" for="name">Label size</label>
                    <select class="form-control form-control-sm" v-model="product.label_size">
                        <option value="L" selected>Large</option>
                        <option value="S">Small</option>
                        <option value="T">Tiny</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="form-group col">
                    <label class="col-form-label" for="name">Default cost</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-prepend">
                            <span class="input-group-text">$</span>
                        </div>
                        <input
                                class="form-control"
                                type="number"
                                step="0.01"
                                min="0"
                                required
                                @change="formatCost"
                                v-model="product.default_cost"
                        />
                    </div>
                </div>
                <div class="form-group col">
                    <label class="col-form-label" for="name">Shipped in quantity</label>
                    <input
                        class="form-control form-control-sm"
                        type="number"
                        min="1"
                        required
                        v-model="product.shipped_in_quantity"
                    />
                </div>
                <div class="form-group col">
                    <label class="col-form-label" for="name">Big Commerce ID</label>
                    <input
                        class="form-control form-control-sm"
                        type="number"
                        min="0"
                        v-model="product.big_commerce_id"
                    />
                </div>
            </div>

            <div class="form-group">
                <label class="col-form-label" for="name">Label description</label>
                <textarea
                    class="form-control form-control-sm"
                    type="text"
                    maxlength="50"
                    required
                    v-model="product.label_desc"
                />
            </div>
        </form>
        <BigCommerceProductsModal @select-product="setBigCommerceProduct" />
    </div>
</template>

<script>
import { DEFAULT_IMAGE } from '../../constants';
import { getProduct } from '../../services/ProductService';
import { getBigCommerceProduct, getBigCommerceProducts } from '../../services/BigCommerceService';
import Spinner from 'vue-simple-spinner';
import BigCommerceProductsModal from '../modals/BigCommerceProductsModal.vue';

export default {
    name: 'ProductAddOrUpdteForm',
    components: {
        BigCommerceProductsModal,
        Spinner
    },
    data() {
        return {
            product: {
                name: '',
                label_size: 'L',
                default_cost: (0).toFixed(2),
                shipped_in_quantity: 1,
                big_commerce_id: '',
                label_desc: '',
                image_url: DEFAULT_IMAGE
            },
            bigCommerceProducts: [],
            loadingImage: false
        };
    },
    async created() {
        if (this.$route.params.id) this.getProduct();
    },
    methods: {
        async getProduct() {
            try {
                this.loadingImage = true;
                const response = await getProduct(this.$route.params.id);
                // Create a temp product to make changes to before populating form
                let product = response.data;
                product.default_cost = (product.default_cost / 100).toFixed(2);
                product.shipped_in_quantity = product.shipped_in_quantity || 1;
                this.product = product;
            } catch (error) {
                console.log(error);
            }
            this.loadingImage = false;
        },
        async setBigCommerceProduct(id) {
            try {
                this.loadingImage = true;
                const response = await getBigCommerceProduct(id);
                this.product.name = response.data.name;
                this.product.big_commerce_id = response.data.id;
                this.product.image_url = response.data.images.data[0]
                    ? response.data.images.data[0].url_thumbnail
                    : this.product.image_url;
            } catch (error) {
                console.log(error);
            }
            this.loadingImage = false;
        },
        submitForm() {
            // Create a temp product so that the user cannot see the formatting being done before the submit
            let product = { ...this.product };
            product.default_cost = product.default_cost * 100;
            // Submit the form with the temp product
            this.$emit('submit-form', product);
        },
        resetImage() {
            this.product.image_url = DEFAULT_IMAGE;
        },
        formatCost() {
            this.product.default_cost = parseFloat(this.product.default_cost).toFixed(2);
        }
    }
};
</script>

<style scoped>
.image-container {
    min-width: 150px;
    max-width: 150px;
    max-height: 150px;
    border-radius: 5px;
    position: relative;
}

.image-container img {
    min-width: 150px;
    max-width: 150px;
    max-height: 150px;
    box-shadow: 0px 0px 1px gray;
    border-radius: 5px;
}

.image-spinner {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    transform: translateY(-50%);
}

.opacity {
    opacity: 0.3;
}

.input-group-prepend {
    height: 31px;
}
</style>

<template>
    <div class="container my-4">
        <div class="card">
            <div class="card-header">
                <div class="d-flex align-items-center">
                    <span>{{ headerText }}</span>
                    <small class="ml-3">(Click image to reset)</small>
                    <button
                        type="button"
                        data-toggle="modal"
                        data-target="#big-commerce-products-modal"
                        class="btn btn-sm btn-light ml-auto"
                    >Big Commerce products</button>
                </div>
            </div>
            <div class="card-body">
                <ProductAddOrUpdateForm @submit-form="submitForm" />
            </div>
            <div class="card-footer d-flex align-items-center">
                <SubmitButton class="ml-auto" :form="'product-form'" :btnDisabled="submitting" />
            </div>
        </div>
    </div>
</template>

<script>
import { addProduct, updateProduct } from '../../services/ProductService';
import SubmitButton from '../../components/shared/SubmitButton.vue';
import ProductAddOrUpdateForm from '../../components/forms/ProductAddOrUpdateForm.vue';

export default {
    name: 'ProductsAdd',
    components: {
        ProductAddOrUpdateForm,
        SubmitButton
    },
    data() {
        return {
            loadingImage: false,
            submitting: false
        };
    },
    computed: {
        headerText() {
            return this.$route.params.id ? 'Edit product' : 'Add product';
        }
    },
    methods: {
        async submitForm(product) {
            try {
                this.submitting = true;
                product.big_commerce_id = !!product.big_commerce_id ? product.big_commerce_id : null;
                if (this.$route.params.id) {
                    await updateProduct(product);
                    return this.$router.push({ name: 'product-list' });
                }
                await addProduct(product);
                this.$router.push({ name: 'product-list' });
            } catch (error) {
                console.log(error);
            }
            this.submitting = false;
        }
    }
};
</script>

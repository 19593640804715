<template>
    <div class="modal fade" id="big-commerce-products-modal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <b class="modal-title">Big Commerce products</b>
                </div>
                <div class="modal-body">
                    <ul class="list-group-flush mb-0 pl-0">
                        <li
                            style="cursor: pointer"
                            class="list-group-item list-group-item-action px-0 border-0"
                            v-for="product in bcProducts"
                            :key="product.id"
                            @click="selectProduct(product.id)"
                        >{{ product.name }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getBigCommerceProducts } from '../../services/BigCommerceService';
import { getProducts } from '../../services/ProductService';

export default {
    name: 'BigCommerceProductsModal',
    data() {
        return {
            bcProducts: []
        };
    },
    async created() {
        this.getBCProducts();
    },
    methods: {
        async getBCProducts() {
            try {
                const response = await getBigCommerceProducts();
                this.bcProducts = response.data;
                this.filterBCProducts();
            } catch (error) {
                console.log(error);
            }
        },
        async filterBCProducts() {
            try {
                const bcIds = [];
                const response = await getProducts();
                response.data.forEach(product => {
                    if (product.id != this.$route.params.id) {
                        bcIds.push(product.big_commerce_id);
                    }
                });
                this.bcProducts = this.bcProducts.filter(bcProduct => !bcIds.includes(bcProduct.id));
            } catch (error) {
                console.log(error);
            }
        },
        selectProduct(id) {
            this.$emit('select-product', id);
            $('#big-commerce-products-modal').modal('hide');
        }
    }
};
</script>
